




























import type { Product } from '@/types';
import Vue, { PropType } from 'vue';

export default Vue.extend({
  props: {
    product: {
      type: Object as PropType<Product>,
      required: true
    },
    noClick: Boolean,
    close: Boolean
  },
  computed: {
    title(): string {
      return `id: ${this.product.product_key}`;
    }
  },
  methods: {
    onClick() {
      if (this.noClick) {
        return;
      }

      this.$store.navigate('/developers', {
        id: this.product.id
      });
    },
    onClose() {
      this.$emit('close', this.product.id);
    }
  }
});
