





























































import Vue from 'vue';
import StoreAlert from '@/components/StoreAlert.vue';
import Card from './Card.vue';
import {Developer, SortOption, TableData, TableOptions} from '@/types';
import {debounce} from '@/helpers';
import CreateForm from './CreateForm.vue';

function defaultOptions() : TableOptions {
  return {
    sortBy: ['id'],
    sortDesc: [true],
    page: 1,
    itemsPerPage: 20
  };
}

export default Vue.extend({
  name: 'Developers',
  components: {StoreAlert, Card, CreateForm},
  data(): TableData<Developer> {
    return {
      headers: [
        {value: 'id', text: 'ID'},
        {value: 'name', text: 'Name'},
        {value: 'email', text: 'Email'},
        {value: 'created_at', text: 'Created at'},
        {value: 'updated_at', text: 'Updated at'}
      ],
      items: [],
      isLoading: false,
      options: defaultOptions(),
      aggregate: undefined,
      total: 0,
      expanded: []
    };
  },
  watch: {
    $hash() {
      this.$store.updateTableOptions(this.options, defaultOptions());
      this.load();
    }
  },
  computed: {
    sort(): SortOption[] {
      const {sortBy, sortDesc} = this.options;
      return (sortBy as string[]).map((column, i) => {
        return {
          column: `developers.${column}`,
          order: sortDesc[i] ? 'desc' : 'asc'
        };
      });
    }
  },
  methods: {
    updateOptions(options: TableOptions) {
      this.$store.setTableOptions(options, defaultOptions());
    },
    load() {
      this.cleanError();
      this.startLoading();

      const {page, itemsPerPage} = this.options;

      const where = Object.assign({}, this.$store.query) as Record<string, unknown>;

      if (typeof where.license_types === 'string') {
        where.license_types = where.license_types.split(',');
      }

      this.$store.api.developers({
        where,
        sort: this.sort,
        page,
        perPage: itemsPerPage
      }).then(({items, total, aggregate}) => {
        this.stopLoading();
        this.items = items;
        this.total = total;
        this.aggregate = aggregate;
      }).catch(this.setError);
    }
  },
  created() {
    this.$store.updateTableOptions(this.options, defaultOptions());
    this.load = debounce(this.load, 200, true);
    this.load();
  }
});
