import Vue from 'vue';
import {parseISO, format, differenceInDays} from 'date-fns';
import {HttpError, NetworkError} from '@/errors';
import {secondsToIsoDate} from '@/helpers';

export default Vue.extend({
  data(): { vError: HttpError | Error | null; isLoading: boolean; isSaving: boolean; } {
    return {
      vError: null,
      isLoading: false,
      isSaving: false
    };
  },
  computed: {
    $url(): string {
      return this.$store.url;
    },
    $hash(): string {
      return this.$store.hash;
    },
    validationErrors(): { path: string[], message: string }[] {
      if (this.vError instanceof HttpError) {
        return this.vError?.errors || [];
      }

      return [];
    },
    defaultFooterProps() {
      return {
        'items-per-page-text': 'Per page',
        'items-per-page-options': [20, 100, 1000]
      };
    }
  },
  methods: {
    pathErrors(path: string): string[] {
      return this.validationErrors.flatMap(ve => {
        if (ve.path[0] === path) {
          return [ve.message];
        }

        return [];
      });
    },
    setError(err: Error) {
      this.isLoading = false;

      if (err instanceof HttpError) {
        console.error('http error:', err);
        if (err.status === 403) {
          this.$store.flushToken();
        }
      }

      if (err instanceof NetworkError) {
        this.$store.error = err;
      }

      this.vError = err;
    },
    cleanError() {
      this.vError = null;
    },
    startLoading() {
      this.isLoading = true;
    },
    stopLoading() {
      this.isLoading = false;
    },
    startSaving() {
      this.isSaving = true;
    },
    stopSaving() {
      this.isSaving = false;
    },
    formatBytes(num: string | number | undefined) {
      if (num === undefined) {
        return '';
      }

      const v = Number(num);

      if (Number.isNaN(v)) {
        return 'NaN';
      }

      const mib = v / (1024 * 1024);

      if (mib > 1024) {
        return (mib / 1024).toFixed(0) + ' GiB';
      }

      return mib.toFixed(0) + ' MiB';
    },
    formatDate(date: string | null) {
      if (date === null) {
        return 'NULL';
      }
      try {
        return format(parseISO(date), 'yyyy-MM-dd HH:mm');
      } catch (err) {
        return err instanceof Error ? err.message : String(err);
      }
    },
    daysFromNow(date: string) {
      return differenceInDays(parseISO(date), new Date());
    },
    parseDate(date: string): Date {
      return parseISO(date);
    },
    secondsToIsoDate(seconds: number) {
      return secondsToIsoDate(seconds);
    },
    isDateExpired(date: string) {
      return this.parseDate(date).getTime() < Date.now();
    },
    getInitialState() {
      return ((this.$options?.data instanceof Function) ? this.$options.data : () => {
      }).call(this);
    },
    restoreInitialState() {
      Object.assign(this.$data, this.getInitialState());
    }
  }
});
