<template>
  <a
    :class="{active}"
    :href="path"
    class="menu-item"
    @click.prevent="onClick">
    Subscriptions
  </a>
</template>

<script>
export default {
  data() {
    return {
      path: '/subscriptions'
    };
  },
  computed: {
    active() {
      return this.path === this.$store.path;
    }
  },
  methods: {
    onClick() {
      this.$store.navigate(this.path, {});
    }
  }
};
</script>
