





































import Vue, {PropType} from 'vue';
import {Subscription} from '@/types';
import DeleteSubscription from './DeleteSubscription.vue';
// import EditCustomer from '@/components/actions/EditCustomer.vue';
// import DeleteCustomer from '@/components/actions/DeleteCustomer.vue';
// import RevokeAcademicStatus from '@/components/actions/RevokeAcademicStatus.vue';

export default Vue.extend({
  components: {DeleteSubscription},
  props: {
    id: {
      type: Number as PropType<number>,
      required: true
    }
  },
  data(): { model: Subscription | null } {
    return {
      model: null
    };
  },
  watch: {
    $hash() {
      this.load();
    }
  },
  computed: {

  },
  methods: {
    load() {
      this.$store.api.subscription(this.id).then(it => {
        this.model = it;
      }).catch(this.setError);
    }
  },
  mounted() {
    this.load();
  }
});
