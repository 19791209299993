



















































import DeveloperSelect from '@/components/controls/DeveloperSelect.vue';
import type { CreateProduct } from '@/types';
import Vue from 'vue';

type Data = {
  dialog: boolean,
  error: Error | null,
  attrs: CreateProduct,
};

export default Vue.extend({
  components: {DeveloperSelect},
  data(): Data {
    return {
      dialog: false,
      error: null,
      attrs: {
        developer_id: null,
        name: null,
        description: null
      }
    };
  },
  methods: {
    save() {
      const { name, description, developer_id } = this.attrs;
      this.startSaving();
      this.$store.api.createProduct({
        developer_id,
        name,
        description
      }).then(() => {
        this.$store.change();
        this.closeDialog();
      }).catch(this.setError).then(this.stopSaving);
    },
    closeDialog() {
      this.dialog = false;
      this.restoreInitialState();
    }
  }
});
