


















































import type { CreateSubscription } from '@/types';
import Vue from 'vue';

type Data = {
  dialog: boolean,
  error: Error | null,
  attrs: CreateSubscription,
};

export default Vue.extend({
  data(): Data {
    return {
      dialog: false,
      error: null,
      attrs: {
        customer_id: null,
        product_id: null,
        amount: null
      }
    };
  },
  methods: {
    save() {
      const { customer_id, product_id, amount } = this.attrs;
      this.startSaving();
      this.$store.api.createSubscription({
        customer_id,
        product_id,
        amount
      }).then(() => {
        this.$store.change();
        this.closeDialog();
      }).catch(this.setError).then(this.stopSaving);
    },
    closeDialog() {
      this.dialog = false;
      this.restoreInitialState();
    }
  }
});
