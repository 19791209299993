

















































import type { CreateDeveloper } from '@/types';
import Vue from 'vue';

type Data = {
  dialog: boolean,
  error: Error | null,
  attrs: CreateDeveloper,
};

export default Vue.extend({
  data(): Data {
    return {
      dialog: false,
      error: null,
      attrs: {
        name: null,
        email: null
      }
    };
  },
  methods: {
    save() {
      const { name, email } = this.attrs;
      this.startSaving();
      this.$store.api.createDeveloper({
        name,
        email
      }).then(() => {
        this.$store.change();
        this.closeDialog();
      }).catch(this.setError).then(this.stopSaving);
    },
    closeDialog() {
      this.dialog = false;
      this.restoreInitialState();
    }
  }
});
