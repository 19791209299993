<template>
  <v-app>
    <v-navigation-drawer v-model="$store.drawer" app clipped >
      <slot name="sidebar">
          <component
            :is="item"
            v-for="(item) in menu"
            :key="item"/>
      </slot>
    </v-navigation-drawer>

    <v-app-bar app clipped-left color="main-dark" dark>
      <v-app-bar-nav-icon @click.stop="$store.drawer = !$store.drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>MiAdmin</v-toolbar-title>
      <v-spacer/>
      <v-btn icon @click="$store.change">
        <v-icon>mdi-reload</v-icon>
      </v-btn>
      <v-btn icon @click="$store.flushToken">
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <slot/>
        <notifications/>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import LayoutFooter from '@/components/LayoutFooter';
import Notifications from '@/components/Notifications';

export default {
  components: { Notifications, LayoutFooter },
  data() {
    return {
      menu: [
        'MainNav',
        'ContractsNav',
        'CustomersNav',
        'DevelopersNav',
        'ProductsNav',
        'SubscriptionsNav',
        'EmailsNav',
        'ConfirmationMailsNav',
        'ContractMailsNav',
        'HistoryNav',
        'IpsNav',
        'TasksNav',
        'VerifyTokenNav',
        'AthenaNav',
        'UsersNav'
      ]
    };
  },
  methods: {
    to(page) {
      this.$store.navigate(page, {});
    }
  }
};
</script>
