var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('layout',[_c('v-col',{staticClass:"mb-4"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('store-alert'),_c('div',{staticClass:"table-container"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"mobile-breakpoint":0,"expanded":_vm.expanded,"footer-props":_vm.defaultFooterProps,"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.total,"loading":_vm.isLoading,"fixed-header":"","show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event},"update:options":[function($event){_vm.options=$event},_vm.updateOptions]},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"panel"},[_c('div',{staticClass:"title"},[_c('v-icon',[_vm._v("mdi-account")]),_vm._v(" Developers ")],1),_c('div',{staticClass:"spacer"}),_c('query-filters'),_c('CreateForm',{on:{"created":_vm.load}})],1)]},proxy:true},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('string',{attrs:{"value":item.status}})]}},{key:"item.customer",fn:function(ref){
var item = ref.item;
return [_c('customer-chip',{attrs:{"customer":item.customer}})]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.created_at))+" ")]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.updated_at))+" ")]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('Card',{attrs:{"id":item.id}})],1)]}},{key:"footer",fn:function(){return undefined},proxy:true}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }