









































import Vue, {PropType} from 'vue';
import type {Product} from '@/types';

export default Vue.extend({
  props: {
    id: {
      type: Number as PropType<number>,
      required: true
    }
  },
  data(): { model: Product | null } {
    return {
      model: null
    };
  },
  watch: {
    $hash() {
      this.load();
    }
  },
  computed: {

  },
  methods: {
    load() {
      this.$store.api.product(this.id).then(it => {
        this.model = it;
      }).catch(this.setError);
    }
  },
  mounted() {
    this.load();
  }
});
