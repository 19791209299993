import '@/styles/main.scss';
import Vue from 'vue';
import App from './App.vue';
import vuetify from '@/plugins/vuetify';
import store from '@/plugins/store';
import Common from '@/mixins/Common';
import Layout from '@/components/Layout.vue';
import Boolean from '@/components/Boolean.vue';
import String from '@/components/String.vue';
import Date from '@/components/Date.vue';
import QueryFilters from '@/components/QueryFilters.vue';
import CustomerSelect from '@/components/controls/CustomerSelect.vue';
import ActivateContract from '@/components/actions/ActivateContract.vue';
import DeactivateContract from '@/components/actions/DeactivateContract.vue';
import EditContract from '@/components/actions/EditContract.vue';
import CreateContract from '@/components/actions/CreateContract.vue';
import CreateContractForOrder from '@/components/actions/CreateContractForOrder.vue';
import GetContractLicense from '@/components/actions/GetContractLicense.vue';
import CreateCustomer from '@/components/actions/CreateCustomer.vue';
import CustomerCard from '@/components/cards/CustomerCard.vue';
import ContractCard from '@/components/cards/ContractCard.vue';
import CustomersFilter from '@/components/filters/CustomersFilter.vue';
import CustomerEmailsFilter from '@/components/filters/CustomerEmailsFilter.vue';
import ContractMailsFilter from '@/components/filters/ContractMailsFilter.vue';
import ContractChip from '@/components/chips/ContractChip.vue';
import ContractMailChip from '@/components/chips/ContractMailChip.vue';
import CustomerChip from '@/components/chips/CustomerChip.vue';
import DatePicker from '@/components/controls/DatePicker.vue';
import MainNav from '@/components/menu/MainNav.vue';
import ContractsNav from '@/components/menu/ContractsNav.vue';
import CustomersNav from '@/components/menu/CustomersNav.vue';
import EmailsNav from '@/components/menu/EmailsNav.vue';
import ContractMailsNav from '@/components/menu/ContractMailsNav.vue';
import ConfirmationMailsNav from '@/components/menu/ConfirmationMailsNav.vue';
import HistoryNav from '@/components/menu/HistoryNav.vue';
import IpsNav from '@/components/menu/IpsNav.vue';
import TasksNav from '@/components/menu/TasksNav.vue';
import VerifyTokenNav from '@/components/menu/VerifyTokenNav.vue';
import AthenaNav from '@/components/menu/AthenaNav.vue';
import UsersNav from '@/components/menu/UsersNav.vue';
import CreateUser from '@/components/actions/CreateUser.vue';
import EditUser from '@/components/actions/EditUser.vue';
import DeleteUser from '@/components/actions/DeleteUser.vue';
import SubscriptionsNav from '@/components/menu/SubscriptionsNav.vue';
import DevelopersNav from '@/components/menu/DevelopersNav.vue';
import ProductSelect from '@/components/controls/ProductSelect.vue';
import DeveloperChip from './components/chips/DeveloperChip.vue';
import ProductsNav from './components/menu/ProductsNav.vue';

Vue.config.productionTip = false;

Vue.mixin(Common);

Vue.use(store);

Vue.component('Layout', Layout);
Vue.component('Boolean', Boolean);
Vue.component('String', String);
Vue.component('Date', Date);
Vue.component('QueryFilters', QueryFilters);
Vue.component('CustomerSelect', CustomerSelect);
Vue.component('ProductSelect', ProductSelect);
Vue.component('ActivateContract', ActivateContract);
Vue.component('DeactivateContract', DeactivateContract);
Vue.component('EditContract', EditContract);
Vue.component('CreateContract', CreateContract);
Vue.component('CreateContractForOrder', CreateContractForOrder);
Vue.component('GetContractLicense', GetContractLicense);
Vue.component('CreateCustomer', CreateCustomer);
Vue.component('CustomerCard', CustomerCard);
Vue.component('ContractCard', ContractCard);
Vue.component('CustomersFilter', CustomersFilter);
Vue.component('CustomerEmailsFilter', CustomerEmailsFilter);
Vue.component('ContractMailsFilter', ContractMailsFilter);
Vue.component('ContractChip', ContractChip);
Vue.component('ContractMailChip', ContractMailChip);
Vue.component('CustomerChip', CustomerChip);
Vue.component('DatePicker', DatePicker);
Vue.component('MainNav', MainNav);
Vue.component('ContractsNav', ContractsNav);
Vue.component('CustomersNav', CustomersNav);
Vue.component('EmailsNav', EmailsNav);
Vue.component('ContractMailsNav', ContractMailsNav);
Vue.component('ConfirmationMailsNav', ConfirmationMailsNav);
Vue.component('HistoryNav', HistoryNav);
Vue.component('IpsNav', IpsNav);
Vue.component('TasksNav', TasksNav);
Vue.component('VerifyTokenNav', VerifyTokenNav);
Vue.component('AthenaNav', AthenaNav);
Vue.component('UsersNav', UsersNav);
Vue.component('CreateUser', CreateUser);
Vue.component('EditUser', EditUser);
Vue.component('DeleteUser', DeleteUser);
Vue.component('SubscriptionsNav', SubscriptionsNav);
Vue.component('DevelopersNav', DevelopersNav);
Vue.component('ProductsNav', ProductsNav);
Vue.component('DeveloperChip', DeveloperChip);

new Vue({
  vuetify,
  render: h => h(App)
}).$mount('#app');
