































import Vue, { PropType } from 'vue';
import { Developer } from '@/types';

export default Vue.extend({
  props: {
    developer: {
      type: Object as PropType<Developer>,
      required: true
    },
    noClick: Boolean,
    close: Boolean
  },
  computed: {
    title(): string {
      return `id: ${this.developer.id}`;
    },
    color(): string {
      return 'accent';
    }
  },
  methods: {
    onClick() {
      if (this.noClick) {
        return;
      }

      this.$store.navigate('/developers', {
        id: this.developer.id
      });
    },
    onClose() {
      this.$emit('close', this.developer.id);
    }
  }
});
